import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "animate.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    animation: "fadeIn 2s ease-in-out",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(4),
    textTransform: "uppercase",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: "#FDC92B",
  },
  audioContainer: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
}));

function SegundoEpisodio() {
  const classes = useStyles();

  return (
    <>  
       <div class="TituloArtigoI">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Divulgação Científica</h1>
                    </div>
                </div>
            </div>

      <Container className={classes.root}>
        <Card>
          <CardContent>
            <Typography variant="h5" className={classes.sectionTitle}>
              Terceiro Episódio: Entre a Fábula e a Moralidade: uma investigação sobre Calila e Dimna
            </Typography>
            <Typography variant="body1" paragraph>
            Neste episódio final, intitulado “Entre a Fábula e a Moralidade: uma investigação sobre
Calila e Dimna”, adotamos uma abordagem totalmente distinta da que vínhamos
tendo. Em vez de narrar um conto, transformamos o podcast em um diálogo entre dois
estudantes, configurando uma dinâmica de perguntas e respostas. Dessa forma, de
maneira didática e acessível, discutiram-se temáticas como os exempla, as narrativas
medievais, o gênero literário “Espelho de Príncipe”, a Corte de Castela e,
principalmente, o grupo de pesquisa “O Ensino da Fé Cristã na Península Ibérica”,
incluindo, seu banco de dados “Obras pastorais e doutrinárias do mundo ibérico.”
Assim, em nossa terceira e última produção, tivemos um objetivo exclusivamente
instrutivo, deixando claros todos os pontos importantes abordados nos episódios
anteriores e instigando os ouvintes a continuarem a explorar as produções literárias
e/ou pastorais medievais discutidas no âmbito do projeto.            
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Apresentadores
                </Typography>
                <Typography variant="body1">
                Bruna Dias Souza Freire (História UNESP – Assis)
                </Typography>
                <Typography variant="body1">
                Roberto Caliani Janeiro Filho (História UNESP – Assis)
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Produção, Roteiro e Edição
                </Typography>
                <Typography variant="body1">
                Bruna Dias Souza Freire (História UNESP – Assis)
                </Typography>
                <Typography variant="body1">
                Roberto Caliani Janeiro Filho (História UNESP – Assis)

                </Typography>
              </Grid>
            </Grid>

            <Typography variant="h6" className={classes.sectionTitle}>
              Fonte
            </Typography>
            <Typography variant="body1" paragraph>
            CALILA E Dimna. Ed. Juan Manuel Cacho Blecua e María Jesús Lacarra. Madrid:
            Castalia, 1985.            </Typography>

            <Typography variant="h6" className={classes.sectionTitle}>
              Créditos
            </Typography>
            <ul>
              <li>
                Rosa das Rosas (Cantigas de Santa Maria: CSM 383) – <a href="https://www.youtube.com/watch?v=4QPWvuV3oyg;" target="_blank" rel="noopener noreferrer">Youtube</a>
              </li>
              <li>
              Boca Torcida. Eduardo Paniagua. Música Antigua – <a href="https://www.youtube.com/watch?v=mPYFttI9bn0" target="_blank" rel="noopener noreferrer">Youtube</a>
              </li>
               
            </ul>
         

          <CardActions className={classes.audioContainer}>
            <Typography variant="h6" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#333', marginBottom: '1rem' }}>
              Ouça o Episódio:
            </Typography>
            <audio controls style={{ width: '100%', border: '2px solid #4CAF50', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <source src="https://bucket.server.umahistoriadapeninsula.com/podcast/Produto3_Entre_a_Fabula_e_a_Moralidade_Uma_Investigacao_de_Kalila_e_Dimna.mp3" type="audio/mpeg" />
              Seu navegador não suporta o player de áudio.
            </audio>
          </CardActions>

          <Typography variant="h6" className={classes.sectionTitle}>
  Créditos Detalhados
</Typography>
<Typography variant="body1" paragraph>
  00:00 até 02:20; 10:20 até 12:00: Cantigas de Santa Maria, CSM 383: O ffondo do mar tan chão (Remastered) · Jordi Savall. La Capella Reial de Catalunya · Hespèrion XXI Cantigas de Santa Maria (Remastered) ℗ 2017 Alia Vox. Disponível em: {" "}
  <a href="https://www.youtube.com/watch?v=4QPWvuV3oyg" target="_blank" rel="noopener noreferrer">
    YouTube
  </a>
</Typography>
<Typography variant="body1" paragraph>
  02:20 até 10:20: Boca Torcida. Eduardo Paniagua. Música Antigua: Alfonso X El Sabio. Cantigas de Viola de Rueda ℗ 2005 Pneuma Released on: 2005-04-04. Disponível em: {" "}
  <a href="https://www.youtube.com/watch?v=mPYFttI9bn0" target="_blank" rel="noopener noreferrer">
    YouTube
  </a>

</Typography>
<Typography variant="body1" paragraph>
  E-mail para contato: historiaemkalilaedimna@gmail.com
</Typography>
            </CardContent>
        </Card>
      </Container>
    </>
  );
}

export default SegundoEpisodio;
