import React, { useEffect } from 'react';
import './ArtigoI.css';
import img1 from '../../../assets/img/Artigos/Pecar_com_a_lingua_na_Idade_Media_IMG.jpeg'
import img2 from '../../../assets/img/Pecar_com_a_linguaIMG.png'
import img3 from '../../../assets/img/Pecar_com_a_linguaIMG2.png'

import Footer from '../../Footer';
import { colors } from '@material-ui/core';


const Pecar_com_a_lingua_na_Idade_Media = () => {
    useEffect(() => {
        // Envia o evento de visualização de página para o Google Analytics
        window.gtag('config', 'G-T3KBNL1ZLF', {
            page_path: 'https://www.googletagmanager.com/gtag/js?id=G-T3KBNL1ZLF'
        });
    }, []);

    return (
        <div>
            <div class="TituloArtigoI">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Divulgação Científica</h1>
                    </div>
                </div>
            </div>


            

            <div className="containerDivulgacao">

            <h1 className="titleDivulgacao">
            Pecar com
a língua na
Idade Média
 
        </h1><br></br>
        <p className="subtitleDivulgacao">
        Tese de livre-docência investiga os pecados da língua e a sua correção na península Ibérica medieval  </p>       <div className="text-contentDivulgacao">
        <p className="highlight-letter">
       
        </p>
</div>


                <div className="book-image">
                    <img src={img1} alt="Capa do Livro"
                        style={{ width: '100%', maxWidth: '800px', height: 'auto' }}
                    />
                     
                </div>
                <main className="main-contentDivulgacao">
                     
                    <p className="subtitleDivulgacao"> </p> 
                    <div className="contentDivulgacao">
                         
                        </div>
                    <div className="contentDivulgacao">
                        <div className="book-image">
                        </div>
                        <div className="text-contentDivulgacao">
                            <p className="highlight-letter">
                            "A língua é o chicote do corpo”. Já dizia um dos ditados mais populares no Brasil a respeito desse pequeno órgão corporal, capaz de
                            grandes prejuízos quando não bem utilizado. Na Europa da Idade Média, a pecaminosidade da fala 
                            foi alvo de preocupação da Igreja, instituição influente no período. O bom uso da língua, 
                            ou seja, a pronúncia de palavras com fins virtuosos e comprometida com a verdade, foi 
                            estimulado como um meio para a manutenção da ordem, da harmonia social e da fé cristã.
                            
                             </p>
         <div className="book-image">
        <img src={img2} alt="Capa do Livro" style={{ width: '500px', height: 'auto' }} />   
        </div>
          <div className="text-contentDivulgacao">
          <p> Ilustração da língua pecaminosa. Manuel des péchés (Exemplum 30, Pecado da Avareza e da Cobiça). Princeton, Princeton University Library, MS Taylor (ca. 1280-1298), f. 52v.
          </p>

</div>  
<p className="paragraph-content ">
As palavras e a parte do corpo pela qual elas são transmitidas – a língua e, em alguns casos, 
a boca –, ganharam um grande interesse dos laicos e, principalmente, dos homens da Igreja, entre os 
fins do século XII e durante o século XIII – momento de surgimento de novas ordens religiosas dedicadas 
à pregação, como os franciscanos e os dominicanos – e da centralidade da confissão – tornada obrigatória no 
IV Concílio de Latrão (1215). O assunto surge em diversos tipos de documentos 
(tratados teológicos, pastorais, jurídicos; sumas de confessores, recolhas de exempla etc.), 
compreendendo uma extensa lista de pecados da língua <em>(peccata linguae)</em>: mentira, blasfêmia, murmúrio, 
perjúrio, calúnia, detração, disputa, maldição, escárnio etc. O dominicano Guillaume Peyraut (c.1200-1271), 
por exemplo, em sua <em>Suma de vícios e virtudes (Summa de vitiis et virtutibus)</em> – e outros depois dele –, 
chegou mesmo a considerar as transgressões da fala como o oitavo pecado capital.
</p>


                            <p className="paragraph-content ">
                            Sobre o tema se debruçou o historiador Leandro Alves Teodoro em sua tese de livre-docência, 
                            “A emenda da língua no mundo ibérico: as palavras de advogados, 
                            feiticeiros e padres (sécs. XIII-XV)”, defendida em novembro de 2024, 
                            na Faculdade de Ciências e Letras da Universidade Estadual Paulista (UNESP), 
                            Câmpus de Assis, onde também é professor de História Medieval. 
                            Teodoro investigou as recomendações contidas em obras diversas destinadas à correção dos 
                            pecados da língua e o valor conferido ao ato de dizer a verdade nos reinos de Castela e Portugal. 
                            “A custódia da língua se torna matéria obrigatória e um dos pilares da formação moral católica, 
                            especialmente a partir do século XIII, em que a atenção com as palavras do cotidiano passa a ser mais 
                            latente”, afirma.
                                                       </p>
                            <p className="paragraph-content ">
                            Ao longo da pesquisa, um grande conjunto de documentos 
                            (manuscritos, incunábulos, edições) que abordam os cinco sentidos e os pecados 
                            da língua foi reunido e analisado pelo pesquisador. São obras produzidas ou traduzidas,
                             principalmente em terras lusitanas e castelhanas, entre os séculos XIII e XV, de variados 
                             gêneros: espelhos de príncipes, crônicas, <em>exempla</em>, vidas de santos, produção sapiencial, constituições 
                             sinodais e conciliares, sermões, leis, tratados eclesiásticos, livros sobre magia e astrologia etc. 
                             “De fato, foi analisada uma série ampla de documentos. E ainda foi necessário deixar muita coisa de 
                             fora para não ficar um trabalho muito extenso. Trata-se de uma amostra do que se pode encontrar no 
                             período a respeito do papel da língua na formação moral”, conta Teodoro.
                            </p>
                            <p className="paragraph-content ">
                            Ainda que as advertências e os conselhos sobre o uso adequado das palavras fossem destinados a 
                            todos os cristãos, foram escolhidos três personagens no estudo: os advogados, os feiticeiros e os 
                            sacerdotes. “Vários grupos sociais eram alvo de recomendações sobre o uso correto da língua; não apenas 
                            esses três estudados na tese. O trabalho os focou em razão da relação estabelecida entre o tribunal dos 
                            homens e o tribunal divino. São figuras que podem ser relacionadas, de maneiras diferentes, com Maria, 
                            o Diabo e Cristo – os pilares do julgamento da alma. São figuras que estavam presentes em 
                            diferentes lugares e vistas por pessoas de distintas posições sociais”, explica o historiador.
</p>
                        </div>


                    </div>

 
                    
                        <div className="text-contentDivulgacao">
                            <div className="section-title">
                                <h2>“Vários grupos sociais eram alvo de recomendações sobre o uso correto da língua; 
                                    não apenas esses três estudados na tese. O trabalho os focou em razão da relação estabelecida entre o 
                                    tribunal dos homens e o tribunal divino. São figuras que podem ser relacionadas, de maneiras diferentes, 
                                    com Maria, o Diabo e Cristo – os pilares do julgamento da alma”.</h2>
                            </div>
<br></br>
                            <div className="section-title">
                                <h2>Os advogados e Maria</h2>
                            </div>
                            <p className='paragraph-normal'>
                            Os advogados, primeiro grupo estudado por Teodoro, tinham a função, nessas sociedades ibéricas, 
                            de reduzir a discórdia entre os homens e de amparar os pobres. Considerados, metaforicamente, 
                            “a língua e a boca” do corpo social, por se utilizarem das palavras, esperava-se que esses 
                            profissionais agissem com honestidade em prol do bem comum. As críticas mais comumente encontradas 
                            nas constituições e em outros textos da época sobre os advogados, como revelaram a pesquisa, 
                            referem-se ao descomprometimento com a verdade nos pleitos. Muitos eram acusados de cometerem 
                            prevaricação – compreendida, entre outras definições, como a falsificação de testemunhas – e de serem cobiçosos e 
                            avarentos.
                            </p>
                            <p className="paragraph-content">
                            A figura dos advogados contrastava, conforme observou o historiador, com a figura de Maria, 
                            tida como intercessora e advogada dos pecadores e dos desamparados. Acreditava-se que a mãe de Cristo 
                            atuaria em defesa daqueles que a ela recorressem – como os desassistidos (órfãos e viúvas) e os demais 
                            aflitos – no tribunal divino, ou seja, o Juízo final – momento em que Cristo retornaria à Terra para 
                            julgar as almas. A santa oferecia uma defesa justa e infalível frente à defesa dos advogados, 
                            os quais desvirtuavam as palavras em benefício próprio. “As diferenças entre as críticas dirigidas aos 
                            advogados e o enaltecimento da imagem de Maria no tribunal das almas permitem analisar não apenas uma 
                            devoção mais ampla, que tocava os leigos no fim da Idade Média, mas igualmente o deslocamento entre dois 
                            tipos de fala de defesa: uma humana, contaminada pelos pecados fruto de vícios; e outra santa, nutrida 
                            pela graça divina, sinônima de virtude e certeira no reconhecimento dos méritos de seus devotos”, 
                            conclui o pesquisador. A devoção mariana oferecia, portanto, um modelo de justiça baseado na 
                            honestidade e voltado para a preparação do tribunal celestial, o qual devia inspirar os advogados.
                            </p>
                        </div>
                  

                    <div className="text-contentDivulgacao">

                    <div className="section-title">
                                <h2>Os feiticeiros: palavras diabólicas</h2>
                            </div>
                        <p className="paragraph-normal">
                        Havia, porém, outro perfil social cujo uso das palavras teve um aspecto ainda mais grave. Teodoro 
                        constatou que aqueles que se utilizavam da magia, como os feiticeiros, os adivinhos e os idólatras, 
                        ocupavam uma posição superior na hierarquia dos pecados da língua. De um modo geral, atribuía-se a esses 
                        indivíduos a capacidade de curar o mau-olhado, auxiliar a gravidez, localizar um bem perdido, prever 
                        acontecimentos, entre outras faculdades, por meio de profecias, adivinhações, previsões, invocações, 
                        visões, conselhos, revelações etc. Tais práticas eram entendidas pela Igreja como um pecado contra o 
                        primeiro mandamento divino (“Amar a Deus sobre todas as coisas”). A língua, nesse contexto, era 
                        considerada como um instrumento de manifestação demoníaca,já que era usada para invocar espíritos ou para pronunciar palavras ilícitas.
                         </p>

                         <p className="paragraph-content">
                         Aqueles que se serviam de adivinhos também eram censurados e advertidos. Com base nos documentos 
                         normativos e nos escritos de letrados e eclesiásticos que condenavam as artes mágicas, o historiador 
                         constatou que uma parte das sociedades portuguesa e castelhana, nesse momento, tendia a conciliar 
                         devoção cristã e feitiçaria. Essa inclinação ocorria em vários grupos sociais, desde gentes simples 
                         até figuras importantes como clérigos e nobres. “Era uma prática diluída na sociedade: assim como havia 
                         camponeses que, provavelmente, reprovavam a adivinhação, confessavam seus pecados e assistiam a missas, 
                         existiam nobres acusados de idolatria”. Além disso, Teodoro mostra que as formas de combater as 
                         superstições foram variadas – indo da pregação à excomunhão. O clérigo Martín Perez (séc. XIV), por 
                         exemplo, um dos autores examinados na tese, em seu “Livro das confissões” <em>(Libro de las confesiones)</em>, 
                         orientava os fiéis que se diziam vítimas de uma arte maligna a dirigirem palavras aos santos ou a 
                         confessarem os seus pecados, entre outras recomendações, em vez de buscarem sortílegos e adivinhos.                        </p>
                    </div>
                    
                    <div className="book-image">
        <img src={img3} alt="Capa do Livro" style={{ width: '500px', height: 'auto' }} />  
        <p>O <em>Libro de la Justiçia de la Vida Espiritual e de la Perfección de la Iglesia Militante,</em> atribuído ao arcebispo de Sevilha, D. Pedro Gómez de Albornoz Barroso (1321/2-1374), é uma das fontes utilizadas no estudo de Teodoro. Na imagem, fólios que abordam o oitavo mandamento (“Não dirás falso testemunho”), o qual arrola várias formas de injúria – considerada um tipo de transgressão da língua. Madrid, Biblioteca Nacional de España, Mss/9299 [1401-1500], fls. 27v-28r.</p> 
        <p></p>
        </div>
            
                    <div className="text-contentDivulgacao">
                        <div className="section-title">
                            <h2>Sacerdotes: palavras sacras</h2>
                        </div>
                        <div className="text-contentDivulgacao">
                            <p className='paragraph-normal'>
                            Ao contrário dos advogados e dos feiticeiros – cujas palavras eram criticadas e condenadas –, 
                            os clérigos, outro grupo estudado por Teodoro, eram considerados modelos de virtudes, por 
                            serem os mediadores das palavras de Cristo. “A palavra do clérigo era modelar e considerada a mais 
                            sublime justamente por intermediar o humano com o divino”. Em virtude disso, eles deviam saber 
                            disciplinar suas falas, em especial, aqueles que ocupavam cargos importantes na hierarquia 
                            eclesiástica, como os bispos. “Os bispos eram alvos constantes de advertências presentes em diferentes 
                            obras dos séculos XIV e XV, pois suas palavras e gestos funcionavam como instrumentos da graça divina ao 
                            comporem desde ritos, expressos em celebrações, até o encaminhamento dos fiéis para longe dos pecados, 
                            poder este manifestado por meio de suas orientações e punições”, afirma o historiador.

                            </p>


                            <p className='paragraph-content'>
                            Além de lançar luz sobre as políticas pastorais de disciplinamento das palavras e do 
                            comportamento no processo de formação cristã em Castela e Portugal, a pesquisa de Teodoro apontou, 
                            ainda, para o fato de que a língua, ao mesmo tempo em que era condenada por seus pecados, quando 
                            dominada, era vista como um caminho importante para a redenção, já que, por meio dela, se estabelecia a 
                            comunicação entre Deus e os homens (preces, confissão, pregação, celebração, louvores etc.).
                            </p>

                            <div className="contentDivulgacao" >
                                 

                                <div className="text-contentDivulgacao">
                                    <p className="paragraph-content">
                                    O estudo, que deverá chegar ao público nos próximos anos, foi desenvolvido, entre 2019 e 2024, 
                                    no âmbito do auxílio modalidade Jovem Pesquisador “O ensino da fé cristã na península Ibérica”, 
                                    financiado pela Fundação de Amparo à Pesquisa do Estado de São Paulo (FAPESP), e do convênio 
                                    “Instrução e conversão nos mundos dos <em>exempla</em> ibéricos: pilares da moral cristã”, custeado pela 
                                    mesma agência paulista em parceria com o Fundo Nacional Suíço (FNS). Por meio desses dois projetos, 
                                    Teodoro também consolidou um grupo de pesquisa sobre as formas de fazer crer na península Ibérica 
                                    medieval, organizou eventos internacionais e lançou livros individuais e coletivos, entre os quais, 
                                    os “Guias dos costumes cristãos castelhanos: o Espelho da alma e outros opúsculos pastorais dos 
                                    séculos XIII, XIV e XV”, uma antologia de seis opúsculos traduzidos, pela primeira vez, 
                                    para a língua portuguesa, publicado pela Editora da Unicamp, em 2024.
                                     </p>                                 
                                </div>
                                </div>
                                </div>
                            </div>

                            
 


                            <div className="content-columns-artigoII">
                                <div className="column-artigoII">
                                    <h3 id='h3-artigoII'>Materiais consultados</h3>
                                    <p>
                                    CASAGRANDE, Carla; VECCHIO, Silvana. <em>Les péchés de la langue:</em> discipline et éthique de la parole dans la culture médiévale. Paris: Les éditions du Cerf, 1991.
                                        </p>
                                        <p>
                                        TEODORO, Leandro Alves. La corrección de la lengua en <em>exempla</em>  de la <em>Confessio Amantis</em> y del Horto do Esposo. In: BIZZARRI, Hugo O; TEODORO, Leandro Alves. <em>Exemplum y discurso ejemplar en la Península Ibérica.</em> São Paulo: Cultura Acadêmica, 2024, pp. 233-268.                                        </p>
                                </div>
                                
                            </div>
                             
                            <div className="content-columns-artigoII">
                                <div className="column-artigoII">
                                <h3 id='h3-artigoII'>Projetos</h3>
<p>O ensino da fé cristã na península Ibérica, sécs. XIV, XV e XVI (FAPESP n. 17/11111-9). Modalidade: Jovem Pesquisador. Pesquisador responsável: Leandro Alves Teodoro (Unesp-Assis)</p>
                            
                            <p>Instrução e conversão no mundo dos <em>exempla</em> ibéricos: pilares da moral cristã (Processo FAPESP n. 21/02936-0). Modalidade: Convênio. Pesquisador responsável: Leandro Alves Teodoro (Unesp-Assis). Pesquisador responsável no exterior: Hugo Oscar Bizzarri (Universidade de Friburgo – Suíça).</p>
                            </div>
                                
                            </div>

                            <div className="pdf-tbn-evento">
                                <a href="https://bucket.server.umahistoriadapeninsula.com/artigos/Materiadejaneirode2025.pdf" target="_blank" class="btn btn-main mt-20">Baixar Arquivo</a>
                            </div>
                            <p>  <strong>  Patrícia Serieiro.</strong> Bolsista de Jornalismo Científico-IV/ FAPESP (Processo 2024/01457-9).</p>

                           
                            <footer className="footerDivulgacao">
                                <p>O ENSINO DA FÉ CRISTÃ NA PENÍNSULA IBÉRICA (SÉCS. XIV, XV E XVI)</p>
                                <p> Dezembro de 2024</p>
                            </footer>
                        </main>
                    </div>
                    <Footer />
            </div>
            );
    }


export default Pecar_com_a_lingua_na_Idade_Media;


 